/* CONSTANTS */
const WHY_DO_WE_NEED_THIS_LABEL = "Why do we need this?";
const GENERAL_REASON_SUPPORTER =
  "We use this information to check if they've supported Cancer Research UK before. This will help us to make sure that our records are up to date so we can send them relevant information.";
const GENERAL_REASON =
  "We use this information to check if you've supported Cancer Research UK before. This will help us to make sure that our records are up to date so we can send you relevant information.";
const PHONE_NUMBER_REASON =
  "We will use your phone number to call you to offer support for your fundraising activity. We may also send you updates about your fundraising by text message.";
const PHONE_NUMBER_REASON2 =
  "If you give us your phone number we will use it to send you updates about your fundraising by text message.";
const PHONE_NUMBER_REASON3 =
  "We use your phone number to identify if you've supported Cancer Research UK before and help us ensure that our records are up to date.";
const DOB_REASON =
  "We use your date of birth to check if you've supported Cancer Research UK before. This will help us to make sure that our records are up to date so we can send you relevant information.";
const DOB_REASON_SUPPORTER =
  "We use their date of birth to check if they've supported Cancer Research UK before. This will help us to make sure that our records are up to date so we can send them relevant information.";
const EMAIL_REASON =
  "We will use your email address to contact you about your fundraising and to send you a fundraising pack if you chose to receive it by email.";
const EMAIL_PACK_REASON =
  "We use your email address to send a confirmation email to you once you've requested your pack.";

/* TYPES */
export enum SubmitAction {
  NEXT,
  ACTIVITY_SUBMISSION,
  ACTIVITY_SUBMISSION_WITH_FUNDRAISING_PAGE,
}

export enum BackAction {
  BACK,
}

export enum Field {
  ACTIVITY_TYPE,
  ACTIVITY_MONTH,
  ACTIVITY_DATE,
  FIRST_NAME,
  LAST_NAME,
  DATE_OF_BIRTH,
  PHONE_NUMBER,
  ADDRESS,
  EMAIL_ADDRESS,
  RESTRICTION,
  ORGANISATION_NAME,
  BANNER_TEXT,
  FUNDRAISING_TARGET,
  HAS_ONLINE_FUNDRAISING_PAGE,
  MORE_INFO,
  OPT_IN,
  DELIVERY_METHOD,
  DELIVERY_METHOD_2,
  RENDER_IMAGE,
  RENDER_IMAGE_DIY_5K_JOGGER,
  RENDER_IMAGE_PARK_RUNNER,
  RENDER_GIGW_THANK_YOU_IMAGE,
  SU_2C_FB_CTA,
  RUN_60_CTA,
  PRODUCT_QUANTITY,
  CONTACT_CENTRE_SPORTS_PRODUCTS_LOOKUP,
  T_SHIRT_SIZE,
  T_SHIRT_OR_VEST,
  RADIO_SELECT_T_SHIRT_VEST_SIZE,
  MALE_FEMALE_STYLES,
  IRON_ON_TEXT,
  HAVE_ENTERED_EVENT,
  DYNAMIC_EVENT_NAME,
  EVENT_LOOKUP,
  SUPPORTER_NEED_PACK,
  CALL_TO_ACTION,
  SECOND_CALL_TO_ACTION,
  FACEBOOK_FUNDRAISER_CTA,
  LEGACIES_DIGITAL_GUIDE_CTA,
  SPORTS_FORMS_CTA,
  GIFTS_IN_WILLS_GUIDE_IMAGE,
  FUNDRAISING_PAGE,
  MERCHANDISE_TYPE,
  SUPPORT_CALL_BOX,
  INFORMATION_LOOKING_FOR,
}

export type FormPage = {
  fields: Array<Field>;
  secondaryFields?: Array<Field>;
  heading: string;
  secondaryHeading?: string;
  path: string;
  addressHeading?: string;
  activityDateTimeLabel?: string;
  backLinkAction?: BackAction;
  backLinkLabel?: string;
  collapseLabel?: string;
  collapseContent?: string;
  emailAddressLabel?: string;
  emailAddressHintText?: string;
  introText?: string;
  phoneNumberLabel?: string;
  submitButtonAction?: SubmitAction;
  submitButtonLabel?: string;
};

export type FormJourney = {
  name?: string;
  creatable: boolean;
  banner?: true;
  pages: {
    [page: string]: FormPage;
  };
};

/* PAGES */
const introPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "/",
    heading: "{{{header}}}",
    introText: "{{{introTextHtml}}}",
    fields: [Field.EMAIL_ADDRESS],
    submitButtonAction: SubmitAction.NEXT,
    submitButtonLabel: "Next",
  },
  ...overrides,
});

const contactDetailsPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "contact-details",
    heading: "Your details",
    fields: [
      Field.FIRST_NAME,
      Field.LAST_NAME,
      Field.DATE_OF_BIRTH,
      Field.PHONE_NUMBER,
    ],
    collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
    collapseContent: `${DOB_REASON}\n\n${PHONE_NUMBER_REASON2}`,
    submitButtonAction: SubmitAction.NEXT,
    submitButtonLabel: "Next",
    backLinkAction: BackAction.BACK,
    backLinkLabel: "Back",
  },
  ...overrides,
});

const addressPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "your-address",
    heading: "Your address",
    fields: [Field.ADDRESS],
    collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
    collapseContent: GENERAL_REASON,
    submitButtonAction: SubmitAction.NEXT,
    submitButtonLabel: "Next",
    backLinkAction: BackAction.BACK,
    backLinkLabel: "Back",
  },
  ...overrides,
});

const fundraisingTargetPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "your-fundraising-target",
    heading: "{{{fundraisingPageHeading}}}",
    fields: [Field.FUNDRAISING_PAGE, Field.FUNDRAISING_TARGET],
    submitButtonAction: SubmitAction.NEXT,
    submitButtonLabel: "Next",
    backLinkAction: BackAction.BACK,
    backLinkLabel: "Back",
    activityDateTimeLabel: "{{{activityMonthDateHeader}}}",
  },
  ...overrides,
});

const moreAboutYourFundraisingPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "more-about-your-fundraising",
    heading: "More about your fundraising",
    fields: [Field.ACTIVITY_TYPE, Field.ACTIVITY_MONTH, Field.MORE_INFO],
    activityDateTimeLabel: "{{{activityMonthDateHeader}}}",
    submitButtonAction: SubmitAction.NEXT,
    submitButtonLabel: "Next",
    backLinkAction: BackAction.BACK,
    backLinkLabel: "Back",
  },
  ...overrides,
});

const joinUsPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "join-us",
    heading: "Staying in touch with us",
    fields: [Field.OPT_IN],
    submitButtonAction: SubmitAction.ACTIVITY_SUBMISSION_WITH_FUNDRAISING_PAGE,
    submitButtonLabel: "{{{submitButtonLabel}}}",
    backLinkAction: BackAction.BACK,
    backLinkLabel: "Back",
  },
  ...overrides,
});

/**
 * Generates the configuration object for the "thank-you" page.
 * Allows for optional overrides to customise the configuration.
 *
 * This function performs the following steps:
 * 1. Defines the default configuration for the "thank-you" page, including the path, heading, intro text, and fields.
 * 2. Merges the default configuration with any provided overrides, allowing for customisation of the "thank-you" page.
 *
 * @param {Partial<FormPage>} [overrides] - An optional object containing properties to override the default configuration.
 * @returns {FormPage} - The configuration object for the "thank-you" page.
 */

const thankYouPage = (overrides?: Partial<FormPage>) => ({
  ...{
    path: "thank-you",
    heading: "{{{activitySuccessHeader}}}",
    introText: "{{{activitySuccessMessage}}}",
    fields: [],
  },
  ...overrides,
});

/* JOURNEYS */
const crukFormJourney: FormJourney = {
  creatable: false,
  pages: {
    index: introPage({
      fields: [Field.DATE_OF_BIRTH],
      collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
      collapseContent: GENERAL_REASON,
    }),
    "contact-details": contactDetailsPage({
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
      ],
      backLinkLabel: "Back",
      collapseContent: GENERAL_REASON,
    }),
    address: addressPage(),
    "fundraising-target": fundraisingTargetPage(),
    "join-us": joinUsPage(),
  },
};

const alwaysOn2FormJourney: FormJourney = {
  name: "Always On V2",
  creatable: true,
  pages: {
    index: introPage(),
    "contact-details": contactDetailsPage(),
    address: addressPage(),
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.FUNDRAISING_PAGE,
        Field.FUNDRAISING_TARGET,
        Field.ACTIVITY_MONTH,
      ],
    }),
    "join-us": joinUsPage(),
  },
};

const generalFundraisingFormJourney: FormJourney = {
  creatable: true,
  name: "General Fundraising - No pack option",
  pages: {
    index: introPage(),
    "contact-details": contactDetailsPage(),
    address: addressPage(),
    "fundraising-target": fundraisingTargetPage(),
    "join-us": joinUsPage(),
  },
};

const generalFundraisingPackOptionFormJourney: FormJourney = {
  name: "General Fundraising Campaign V2 with a fulfilment option",
  creatable: true,
  pages: {
    index: introPage(),
    "contact-details": contactDetailsPage(),
    address: addressPage(),
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.FUNDRAISING_PAGE,
        Field.FUNDRAISING_TARGET,
        Field.DELIVERY_METHOD,
      ],
    }),
    "join-us": joinUsPage(),
  },
};

const celebrationFormJourney: FormJourney = {
  name: "Always On Celebration",
  creatable: true,
  pages: {
    index: introPage(),
    "contact-details": contactDetailsPage(),
    address: addressPage(),
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.FUNDRAISING_PAGE,
        Field.FUNDRAISING_TARGET,
        Field.ACTIVITY_DATE,
      ],
    }),
    "join-us": joinUsPage(),
  },
};

const organisationFormJourney: FormJourney = {
  name: "Organisation Fundraising",
  creatable: true,
  pages: {
    index: introPage({
      fields: [],
    }),
    "contact-details": contactDetailsPage({
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
      ],
      collapseContent: `If you opt-in to updates from us by phone we will use this phone number to contact you.\n\nWe will use your email address to send you a fundraising pack and to contact you about your fundraising if you opt-in to be contacted.`,
      emailAddressLabel: "Primary contact email address",
      emailAddressHintText:
        "This is the email address we will contact you on about your fundraising.",
      phoneNumberLabel: "Primary contact phone number",
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
    address: addressPage({
      heading: "Your organisation",
      fields: [Field.ORGANISATION_NAME, Field.ADDRESS],
    }),
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.FUNDRAISING_PAGE,
        Field.ACTIVITY_MONTH,
        Field.PRODUCT_QUANTITY,
        Field.FUNDRAISING_TARGET,
      ],
    }),
    "join-us": joinUsPage(),
  },
};

const physicalPackBaseJourney: Partial<FormJourney> = {
  pages: {
    index: introPage({
      fields: [Field.T_SHIRT_SIZE],
      submitButtonLabel: "Next",
    }),
    "contact-details": contactDetailsPage({
      heading: "Personal details",
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
        Field.DATE_OF_BIRTH,
      ],
      collapseContent: `If you opt-in to updates from us by phone we will use this phone number to contact you.\n\n${DOB_REASON}`,
      backLinkLabel: "Back",
    }),
    address: addressPage({
      introText:
        "Please note that we are unable to send physical packs internationally due to the cost to Cancer Research UK.",
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
  },
};
// external customers
const tShirtVestBaseJourney: Partial<FormJourney> = {
  pages: {
    index: introPage({
      submitButtonLabel: "Next",
    }),
    "contact-details": contactDetailsPage({
      heading: "Personal details",
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
        Field.DATE_OF_BIRTH,
      ],
      collapseContent: `If you opt in to updates from us by phone we will use this phone number to contact you.\n\n${DOB_REASON}`,
      backLinkLabel: "Back",
      submitButtonLabel: "Next",
    }),
    address: addressPage({
      introText:
        "Please note that we are unable to send tshirts or vests internationally due to the cost to Cancer Research UK",
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
    "join-us": joinUsPage({
      backLinkLabel: "Back",
    }),
    "thank-you": thankYouPage({
      fields: [Field.FACEBOOK_FUNDRAISER_CTA],
    }),
  },
};

// contact centre
const tShirtVestBaseContactCenterJourney: Partial<FormJourney> = {
  pages: {
    index: introPage({
      fields: [
        Field.EVENT_LOOKUP,
        Field.HAVE_ENTERED_EVENT,
        Field.HAS_ONLINE_FUNDRAISING_PAGE,
        Field.FUNDRAISING_TARGET,
        Field.T_SHIRT_OR_VEST,
        Field.MALE_FEMALE_STYLES,
        Field.RADIO_SELECT_T_SHIRT_VEST_SIZE,
        Field.IRON_ON_TEXT,
      ],
      submitButtonLabel: "Next",
    }),
    "contact-details": contactDetailsPage({
      heading: "Personal details",
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
        Field.DATE_OF_BIRTH,
      ],
      collapseContent: `If they opt-in to updates from us by phone we will use this phone number to contact them.\n\n${DOB_REASON_SUPPORTER}`,
      backLinkLabel: "Back",
      submitButtonLabel: "Next",
    }),
    address: addressPage({
      heading: "Supporter address",
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
      collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
      collapseContent: GENERAL_REASON_SUPPORTER,
    }),
    "join-us": joinUsPage({
      submitButtonLabel: "{{{submitButtonLabel}}}",
      backLinkLabel: "Back",
    }),
  },
};

const run56GivingPageFormJourney: FormJourney = {
  creatable: false,
  pages: {
    ...physicalPackBaseJourney.pages,
    ...{
      address: {
        ...physicalPackBaseJourney.pages!.address,
        ...{ submitButtonLabel: "Next" },
      },
    },
    ...{
      "fundraising-target": fundraisingTargetPage({
        submitButtonLabel: "Next",
      }),
      "join-us": joinUsPage({
        submitButtonLabel: "{{{submitButtonLabel}}}",
        backLinkLabel: "Back",
      }),
    },
  },
};

const run56FormJourney: FormJourney = {
  name: "Facebook Challenges without Giving page",
  creatable: true,
  pages: {
    ...physicalPackBaseJourney.pages,
    ...{
      "join-us": joinUsPage({
        submitButtonAction: SubmitAction.ACTIVITY_SUBMISSION,
        submitButtonLabel: "Place your order",
        backLinkLabel: "Back",
      }),
      "thank-you": thankYouPage({
        fields: [Field.FACEBOOK_FUNDRAISER_CTA, Field.CALL_TO_ACTION],
      }),
    },
  },
};

const run60FormJourney: FormJourney = {
  creatable: false,
  pages: {
    ...run56FormJourney.pages,
    ...{
      "thank-you": {
        ...run56FormJourney.pages["thank-you"],
        ...{ fields: [Field.RUN_60_CTA] },
      },
    },
  },
};

const su2cFbFormJourney: FormJourney = {
  creatable: false,
  pages: {
    ...run56FormJourney.pages,
    ...{
      "thank-you": {
        ...run56FormJourney.pages["thank-you"],
        ...{ fields: [Field.SU_2C_FB_CTA] },
      },
    },
  },
};

const leadGeneration1FormJourney: FormJourney = {
  name: "Lead Generation 1",
  creatable: true,
  pages: {
    index: introPage({
      fields: [Field.EMAIL_ADDRESS],
      collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
      collapseContent: EMAIL_PACK_REASON,
      submitButtonLabel: "Next",
    }),
    "contact-details": contactDetailsPage({
      heading: "Contact details",
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.DATE_OF_BIRTH,
        Field.PHONE_NUMBER,
      ],
      collapseContent: `${PHONE_NUMBER_REASON3}`,
      backLinkLabel: `Back`,
    }),
    address: addressPage({
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
    "join-us": joinUsPage({
      submitButtonAction: SubmitAction.ACTIVITY_SUBMISSION,
      backLinkLabel: "Back",
    }),
    "thank-you": thankYouPage({
      fields: [Field.CALL_TO_ACTION],
    }),
  },
};

// old Lead Gen 2 journey
const giftsInWillsGuideFormJourney: FormJourney = {
  name: "Gifts In Wills Guide",
  creatable: true,
  banner: true,
  pages: {
    index: introPage({
      fields: [
        Field.GIFTS_IN_WILLS_GUIDE_IMAGE,
        Field.INFORMATION_LOOKING_FOR,
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
        Field.DATE_OF_BIRTH,
        Field.SUPPORT_CALL_BOX,
      ],
      collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
      collapseContent: `${EMAIL_PACK_REASON}\n\n${PHONE_NUMBER_REASON3}\n\n${DOB_REASON}`,
      submitButtonLabel: "Next",
    }),
    address: addressPage({
      heading: "Your address",
      collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
      collapseContent: GENERAL_REASON,
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
    "join-us": joinUsPage({
      submitButtonAction: SubmitAction.ACTIVITY_SUBMISSION,
      backLinkLabel: "Back",
    }),
    "thank-you": thankYouPage({
      fields: [
        Field.RENDER_GIGW_THANK_YOU_IMAGE,
        Field.LEGACIES_DIGITAL_GUIDE_CTA,
        Field.CALL_TO_ACTION,
        Field.SECOND_CALL_TO_ACTION,
      ],
    }),
  },
};

const vfFormJourney: FormJourney = {
  creatable: false,
  pages: {
    index: introPage({
      fields: [Field.DELIVERY_METHOD],
      submitButtonLabel: "Next",
    }),
    "contact-details": contactDetailsPage({
      heading: "Personal details",
      fields: [
        Field.DATE_OF_BIRTH,
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.EMAIL_ADDRESS,
        Field.PHONE_NUMBER,
      ],
      collapseContent: `${DOB_REASON}\n\n${EMAIL_REASON}\n\n${PHONE_NUMBER_REASON}`,
      backLinkLabel: `Back`,
    }),
    address: addressPage({
      collapseContent: `${GENERAL_REASON}\n\nIf you requested your fundraising pack by post we'll send it to this address.`,
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.RESTRICTION,
        Field.FUNDRAISING_PAGE,
        Field.FUNDRAISING_TARGET,
      ],
      submitButtonLabel: "Next",
      backLinkLabel: "Back",
    }),
    "more-about-your-fundraising": moreAboutYourFundraisingPage(),
    "join-us": joinUsPage({
      backLinkLabel: "Back",
    }),
  },
};

const vfContactCentreFormJourney: FormJourney = {
  name: "Volunteer Fundraising without a giving page",
  creatable: true,
  pages: {
    ...vfFormJourney.pages,
    ...{
      "join-us": joinUsPage({
        submitButtonAction: SubmitAction.ACTIVITY_SUBMISSION,
        backLinkLabel: "Back",
      }),
      "thank-you": thankYouPage({
        fields: [Field.LEGACIES_DIGITAL_GUIDE_CTA],
      }),
    },
  },
};

const vfFundregFormJourney: FormJourney = {
  creatable: true,
  name: "Volunteer Fundraising with a giving page",
  pages: {
    index: introPage({
      fields: [Field.RENDER_IMAGE, Field.EMAIL_ADDRESS],
      submitButtonLabel: "Next",
    }),
    "contact-details": contactDetailsPage({
      heading: "About you",
      fields: [
        Field.FIRST_NAME,
        Field.LAST_NAME,
        Field.PHONE_NUMBER,
        Field.DATE_OF_BIRTH,
      ],
      collapseContent: `${PHONE_NUMBER_REASON}\n\n${DOB_REASON}`,
      backLinkLabel: `Back`,
    }),
    address: addressPage({
      submitButtonLabel: "Next",
    }),
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.RESTRICTION,
        Field.FUNDRAISING_PAGE,
        Field.FUNDRAISING_TARGET,
        Field.ACTIVITY_TYPE,
        Field.ACTIVITY_MONTH,
        Field.MORE_INFO,
      ],
      submitButtonLabel: "Next",
    }),
    "more-about-your-fundraising": moreAboutYourFundraisingPage({
      heading: "Your fundraising pack",
      fields: [Field.DELIVERY_METHOD_2],
    }),
    "join-us": joinUsPage({
      submitButtonLabel: "{{{submitButtonLabel}}}",
      backLinkLabel: "Back",
    }),
  },
};

const sportsFormExternalUserJourney: FormJourney = {
  name: "Sports Form Journey with T-Shirts and Vests",
  creatable: true,
  pages: {
    ...tShirtVestBaseJourney.pages,
    ...{
      index: introPage({
        ...tShirtVestBaseJourney.pages?.index,
        fields: [
          Field.DYNAMIC_EVENT_NAME,
          Field.HAVE_ENTERED_EVENT,
          Field.FUNDRAISING_TARGET,
          Field.T_SHIRT_OR_VEST,
          Field.MALE_FEMALE_STYLES,
          Field.RADIO_SELECT_T_SHIRT_VEST_SIZE,
          Field.IRON_ON_TEXT,
        ],
      }),
    },
  },
};

const sportsFormContactCenterJourney: FormJourney = {
  name: "Sports Form Journey with T-Shirts and Vests (Contact Center)",
  creatable: true,
  pages: {
    ...tShirtVestBaseContactCenterJourney.pages,
    ...{
      "thank-you": thankYouPage({
        fields: [Field.SPORTS_FORMS_CTA],
      }),
    },
  },
};

const sportsFormPromoterJourney: FormJourney = {
  name: "Sports Form Journey with T-Shirts and Vests (Promoter)",
  creatable: true,
  pages: {
    ...tShirtVestBaseContactCenterJourney.pages,
    index: introPage({
      ...tShirtVestBaseContactCenterJourney.pages?.index,
      fields: [
        Field.EVENT_LOOKUP,
        Field.DYNAMIC_EVENT_NAME,
        Field.HAVE_ENTERED_EVENT,
        Field.FUNDRAISING_TARGET,
        Field.T_SHIRT_OR_VEST,
        Field.MALE_FEMALE_STYLES,
        Field.RADIO_SELECT_T_SHIRT_VEST_SIZE,
        Field.IRON_ON_TEXT,
      ],
    }),
  },
};

const sfChallengesFormJourney: FormJourney = {
  name: "Social Fundraising Challenges with a Giving Page",
  creatable: true,
  pages: {
    ...run56GivingPageFormJourney.pages,
    index: introPage({
      fields: [Field.MERCHANDISE_TYPE, Field.T_SHIRT_SIZE],
      submitButtonLabel: "Next",
    }),
  },
};

const sportsFormWithoutGivingPage = {
  name: "Sports Form Journey with T-shirts and Vests Without a Giving Page",
  creatable: true,
  pages: {
    ...tShirtVestBaseJourney.pages,
    index: introPage({
      ...tShirtVestBaseJourney.pages?.index,
      fields: [
        Field.DYNAMIC_EVENT_NAME,
        Field.HAVE_ENTERED_EVENT,
        Field.T_SHIRT_OR_VEST,
        Field.MALE_FEMALE_STYLES,
        Field.RADIO_SELECT_T_SHIRT_VEST_SIZE,
        Field.IRON_ON_TEXT,
      ],
    }),
    "join-us": joinUsPage({
      submitButtonAction: SubmitAction.ACTIVITY_SUBMISSION,
      submitButtonLabel: "Place your order",
      backLinkLabel: "Back",
    }),
    "thank-you": thankYouPage({
      fields: [Field.CALL_TO_ACTION],
    }),
  },
};

const diyFormJourney: FormJourney = {
  creatable: false,
  pages: {
    index: {
      ...introPage({
        fields: [
          Field.FIRST_NAME,
          Field.LAST_NAME,
          Field.PHONE_NUMBER,
          Field.EMAIL_ADDRESS,
          Field.DATE_OF_BIRTH,
        ],
        secondaryFields: [Field.ADDRESS],
        collapseLabel: WHY_DO_WE_NEED_THIS_LABEL,
        collapseContent: GENERAL_REASON,
        heading: "Your details",
        secondaryHeading: "Your address",
        submitButtonAction: SubmitAction.NEXT,
        submitButtonLabel: "Next",
      }),
    },
    "fundraising-target": fundraisingTargetPage(),
  },
};

const DIYParkRunner: FormJourney = {
  name: "DIY Park Runner",
  creatable: true,
  pages: {
    ...diyFormJourney.pages,
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.FUNDRAISING_PAGE,
        Field.ACTIVITY_TYPE,
        Field.ACTIVITY_MONTH,
        Field.FUNDRAISING_TARGET,
        Field.RESTRICTION,
        Field.MORE_INFO,
      ],
      heading: "Your fundraising details",
    }),
    "more-about-your-fundraising": moreAboutYourFundraisingPage({
      heading: "Your fundraising pack",
      fields: [Field.DELIVERY_METHOD_2, Field.RENDER_IMAGE_PARK_RUNNER],
    }),
    "join-us": joinUsPage(),
  },
};

const DIYCouchTo5KJogger: FormJourney = {
  name: "DIY Couch to 5k Jogger",
  creatable: true,
  pages: {
    ...diyFormJourney.pages,
    "fundraising-target": fundraisingTargetPage({
      fields: [
        Field.FUNDRAISING_PAGE,
        Field.RESTRICTION,
        Field.DELIVERY_METHOD_2,
        Field.RENDER_IMAGE_DIY_5K_JOGGER,
      ],
      heading: "Your fundraising pack",
    }),
    "join-us": joinUsPage(),
  },
};

/* EXPORTS */
export const formJourneys: { [journey: string]: FormJourney } = {
  cruk: crukFormJourney,
  "race-for-life-at-home": crukFormJourney,
  "stand-up-to-cancer": crukFormJourney,
  "always-on": {
    ...crukFormJourney,
    creatable: false,
  },
  "always-on-2": alwaysOn2FormJourney,
  "general-fundraising-2": generalFundraisingFormJourney,
  "general-fundraising-pack-option": generalFundraisingPackOptionFormJourney,
  "sf-challenges": sfChallengesFormJourney,
  celebration: celebrationFormJourney,
  organisation: organisationFormJourney,
  "run-56": run56FormJourney,
  "run-56-GivingPage": {
    ...run56GivingPageFormJourney,
    name: "Facebook Challenges with Giving page",
    creatable: true,
  },
  dryathlon: run56GivingPageFormJourney,
  "run-60": run60FormJourney,
  "su2c-fb": su2cFbFormJourney,
  "sports-form": sportsFormExternalUserJourney,
  "sports-form-without-giving-page": sportsFormWithoutGivingPage,
  "sports-forms-contact-centre": sportsFormContactCenterJourney,
  "sports-form-promoter": sportsFormPromoterJourney,
  "lead-generation-1": leadGeneration1FormJourney,
  "gifts-in-wills-guide": giftsInWillsGuideFormJourney,
  vf: vfFormJourney,
  "vf-fundreg": vfFundregFormJourney,
  "vf-contact-centre": vfContactCentreFormJourney,
  "diy-park-runner": DIYParkRunner,
  "diy-couch-to-5k-jogger": DIYCouchTo5KJogger,
};

/*
 * Retrieve the raw form journey with placeholders.
 */
export function getFormJourneyConfig(journey: string): FormJourney {
  return formJourneys[journey];
}

/*
 * Returns true if the journey is valid.
 */
export function hasFormJourneyConfig(journey: string): boolean {
  return formJourneys[journey] !== undefined;
}
