import { Box, Collapse, Text } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../types";
import renderCustomText from "../utils/renderCustomText";

/**
 * Renders a collapsible section with a label and content.
 * The collapsible sectionis used to provide additional information to the supporter,
 * such as explaining why certain information is needed.
 * @param {FormFieldProps} props - The properties passed into the component.
 * @param {Object} props.formJourneyPage - The form journey page object containing the collapse label and content.
 * @param {string} props.formJourneyPage.collapseLabel - The label for the collapsible section. - The label for the collapsible section.
 * @param {string} props.formJourneyPage.collapseContent - The content for the collapsible section.
 * @param {boolean} [props.formJourneyPage.secondaryHeading] - Optional flag to adjust the margin based on the presence of a secondary heading.
 *
 * @returns {JSXElementConstructor.Element|null} - The collapsible section with the label and content, or null if the label or content is not provided.
 */
const FormCollapseLabelWhyDoWeNeedThis = ({
  formJourneyPage: { collapseLabel, collapseContent, secondaryHeading },
}: FormFieldProps) => {
  return collapseLabel && collapseContent ? (
    <Box marginBottom={secondaryHeading ? "none" : "s"}>
      <Collapse headerTitleText={collapseLabel} id="why-do-we-need-this">
        <Text>{renderCustomText(collapseContent)}</Text>
      </Collapse>
    </Box>
  ) : null;
};

export default FormCollapseLabelWhyDoWeNeedThis;
