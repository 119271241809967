import { Box, Heading, Loader, Text } from "@cruk/cruk-react-components";
import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Event,
  EventListResultSuccess,
  EventResultSuccess,
} from "@cruk/activity-management-schema";
import { sanitize } from "dompurify";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { makeValidationSchema } from "../validation/validationSchema";
import FormCollapseLabelWhyDoWeNeedThis from "../../components/FormCollapseLabelWhyDoWeNeedThis";
import FormPageBannerText from "../../components/FormPageBannerText";
import FormSubmitNextAndBackButton from "../../components/FormSubmitNextAndBackButton";
// containers and components
import ScrollToError from "../../components/ScrollToError";
import { Field, SubmitAction } from "../../constants/journeys";
// data
import { GET_EVENT, GET_EVENTS } from "../../constants/getQueryTypes";
import useGetQuery from "../../hooks/useGetQuery";
import getFormJourney from "../../constants/journeys/formJourney";
import FormPageWrapper from "../../containers/FormPageWrapper";
import FormWrapper from "../../containers/FormWrapper";
import ActivityContext from "../../contexts/ActivityContext";
import RegistrationContext, {
  RegistrationType,
} from "../../contexts/RegistrationContext";
import useDataLayer from "../../hooks/useDataLayer";
import useNavigateToPage from "../../hooks/useNavigateToPage";
import {
  FormFieldProps,
  FormPageProps,
  GIWGLookingForVariables,
} from "../../types";
import { createProcessJob } from "../../utils/amws";
import { trackDataDogError } from "../../utils/dataDog";
import dateOptions from "../../utils/dateOptions";
import filteredHandleChange from "../../utils/filteredHandleChange";
import { JourneyHelper } from "../../utils/journeyHelper";
import {
  DIY_COUCH_TO_5K_JOGGER,
  GIFTS_IN_WILLS_GUIDE,
} from "../../constants/journeys/journeyNames";
import {
  Events,
  FeatureFlags,
  FeatureFlagVariation,
} from "../../utils/optimizely/optimizelyFeatureFlags";
import renderCustomText from "../../utils/renderCustomText";
import ActivityDate from "../fields/ActivityDate";
import ActivityMonth from "../fields/ActivityMonth";
import ActivityType from "../fields/ActivityType";
import Address from "../fields/Address";
import CallToAction from "../fields/CallToAction";
import ContactCentreSportsProductsLookup from "../fields/ContactCentreSportsProductsLookup";
import DateOfBirth from "../fields/DateOfBirth";
import DeliveryMethod from "../fields/DeliveryMethod";
import DeliveryMethod2 from "../fields/DeliveryMethod2";
import DynamicEventName from "../fields/DynamicEventName";
import EmailAddress from "../fields/EmailAddress";
import EventLookup from "../fields/EventLookup";
import FacebookFundraiserCTA from "../fields/FacebookFundraiserCTA";
import FirstName from "../fields/FirstName";
import FundraisingTarget from "../fields/FundraisingTarget";
import GiftsInWillsGuideImage from "../fields/GiftsInWillsGuideImage";
import HasOnlineFundraisingPage from "../fields/HasOnlineFundraisingPage";
import HaveEnteredEvent from "../fields/HaveEnteredEvent";
import IronOnText from "../fields/IronOnText";
import LastName from "../fields/LastName";
import LegaciesDigitalGuideCTA from "../fields/LegaciesDigitalGuideCTA";
import MaleFemaleStyles from "../fields/MaleFemaleStyles";
// Form components
import MerchandiseType from "../fields/MerchandiseType";
import MoreInfo from "../fields/MoreInfo";
import OptIn from "../fields/OptIn";
import OrganisationName from "../fields/OrganisationName";
import PhoneNumber from "../fields/PhoneNumber";
import ProductQuantity from "../fields/ProductQuantity";
import RadioSelectTShirtVestSize from "../fields/RadioSelectTShirtVestSize";
import RenderGIGWThankYouImage from "../fields/RenderGIGWThankYouImage";
import RenderImage from "../fields/RenderImage";
import Restriction from "../fields/Restriction";
import Run60CTA from "../fields/Run60CTA";
import SecondCallToAction from "../fields/SecondCallToAction";
import SportsFormsCTA from "../fields/SportsFormsCTA";
import SU2C60CTA from "../fields/SU2C60CTA";
import SupportCallBox from "../fields/SupportCallBox";
import SupporterNeedPack from "../fields/SupporterNeedPack";
import TShirtOrVest from "../fields/TShirtOrVest";
import TShirtSize from "../fields/TShirtSize";
// images and styles
import "./FormPage.css";

import renderFundraisingPackImage from "../../assets/fundraisingPackImage.jpg";
import renderDIY5kJoggerImage from "../../assets/DIY-5k-jogger.png";
import renderDIYParkRunnerImage from "../../assets/DIY-park-runner.png";

// Optimizely GIWG experiment demo
import useOptimizelyDecision from "../../hooks/useOptimizelyDecision";
import InformationLookingFor from "../fields/InformationLookingFor";
import { useOptimizelyContext } from "../../contexts/OptimizelyContext";
import { OptimizelyDecision } from "@optimizely/optimizely-sdk";

const FormPage: FC<FormPageProps> = ({ journey, currentPage }) => {
  const navigate = useNavigate();
  const activity = useContext(ActivityContext);
  const { registration } = useContext(RegistrationContext);
  const { nextPage, previousPage } = useNavigateToPage(journey, currentPage);
  const { pushPageViewConfirmation } = useDataLayer();
  const [serverValidationError, setServerValidationError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [eventRegistered, setEventRegistered] = useState<Event>();
  const [optInMerchandise, setOptInMerchandise] = useState<boolean | null>(
    null
  );
  const { trackEvent } = useOptimizelyContext();
  const [giwgLookingForVariables, setGiwgLookingForVariables] = useState<{
    dropdown_value: any;
  } | null>(null);
  const [enabledExperimentGIWGVariation, setEnabledExperimentGIWGVariation] =
    useState<boolean | undefined>(undefined);
  const [giwgExperimentInitialized, setGiwgExperimentInitialized] =
    useState(false);

  /* Optimizely development experiment demo */
  const { decision } = useOptimizelyDecision(
    FeatureFlags.DEVELOPMENT_EXPERIMENT_FLAG
  );

  const { enabled: experimentEnabled, variationKey } = decision;

  const enabledExperimentHeaderControl =
    experimentEnabled && variationKey === FeatureFlagVariation.CONTROL;
  const enabledExperimentHeadervariation =
    experimentEnabled && variationKey === FeatureFlagVariation.VARIATION;

  /* Optimizely development experiment demo end */

  /* Optimizely GIWG experiment demo */

  const decisionResult =
    journey === GIFTS_IN_WILLS_GUIDE
      ? useOptimizelyDecision(FeatureFlags.GIWG_ADDITIONAL_QUESTION)
      : { decision: { enabled: false, variationKey: null } };

  useEffect(() => {
    if (journey === GIFTS_IN_WILLS_GUIDE && !giwgExperimentInitialized) {
      const decision = decisionResult.decision as OptimizelyDecision;

      if (decision) {
        const giwgExperimentEnabled = decision.enabled;
        const variationKeyGIWG = decision.variationKey;
        setGiwgLookingForVariables(
          decision.variables as { dropdown_value: any }
        );

        setEnabledExperimentGIWGVariation(
          giwgExperimentEnabled && variationKeyGIWG === FeatureFlagVariation.ON
        );
      }
      setGiwgExperimentInitialized(true);
    }
  }, [journey, decisionResult, giwgExperimentInitialized]);

  /* Optimizely GIWG experiment demo end */

  const {
    location: { search },
  } = window;

  // form journey config
  const formJourneyPage = getFormJourney(journey, activity).pages[currentPage];

  // sports forms utility functions
  const params = new URLSearchParams(search);
  const journeyHelper = new JourneyHelper();
  const eventCode = sanitize(params.get("event_code") as string);

  const { isSportsFormWithEventCodeJourney, isSportsFormContactCentreJourney } =
    journeyHelper;

  // API calls
  const eventQuery = useGetQuery<EventResultSuccess>(
    GET_EVENT,
    eventCode,
    isSportsFormWithEventCodeJourney(journey)
  );
  const eventsQuery = useGetQuery<EventListResultSuccess>(
    GET_EVENTS,
    "Generic Events",
    registration.isGenericEvent
  );

  useEffect(() => {
    if (!eventCode && isSportsFormWithEventCodeJourney(journey)) {
      return navigate("/error");
    }
    setEventRegistered(eventQuery?.data?.event[0]);
  }, [eventQuery?.isSuccess]);

  useEffect(() => {
    if (eventsQuery?.data?.events) setEvents(eventsQuery.data.events);
  }, [eventsQuery?.isSuccess]);

  // configs
  const { urlName, bannerText } = activity;

  const {
    heading,
    introText,
    fields,
    submitButtonAction,
    secondaryFields,
    secondaryHeading,
  } = formJourneyPage;

  const validationSchema = makeValidationSchema(
    formJourneyPage,
    journey,
    activity,
    optInMerchandise,
    enabledExperimentGIWGVariation
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // utility functions

  const getClickableLink = (link: string | undefined) =>
    link && (link.startsWith("http://") || link.startsWith("https://"))
      ? link
      : `http://${link || ""}`;

  const handleError = (error: string) => {
    const errors = error.split(": ");
    const errorMessage = errors[1];
    const errorType = errors[0];
    setSubmitted(false);
    if (errorType === "Validation error") {
      trackDataDogError("Validation error.", { errors });
      switch (errorMessage) {
        case "emailAddress is invalid":
          return setServerValidationError(
            "Please go back and enter a valid email address"
          );
        default:
          return setServerValidationError(errorMessage);
      }
    }
    return navigate("/error");
  };

  // eslint-disable-next-line consistent-return
  const activitySubmissionTask = async (values: RegistrationType) => {
    try {
      trackEvent({ eventKey: Events.GIWG_SIGNUP_EVENT });
      localStorage.removeItem("enabledExperimentGIWGVariation");

      const { id } = await createProcessJob(activity, values);
      nextPage(values);
      pushPageViewConfirmation(activity, id);
    } catch (e) {
      if (e instanceof Error) {
        return handleError(e.message);
      }
      return navigate("/error");
    }
  };

  const activitySubmissionWithFundraisingPageTask = async (
    values: RegistrationType
    // eslint-disable-next-line consistent-return
  ) => {
    try {
      const { id, fundraisingPageRedirectUrl } = await createProcessJob(
        activity,
        values
      );

      if (!fundraisingPageRedirectUrl) {
        // fundraisingPageRedirectUrl was expected but it was empty
        return navigate(`/${urlName}/error/registration`);
      }

      pushPageViewConfirmation(activity, id);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      window.location.href = fundraisingPageRedirectUrl;
    } catch (e) {
      if (e instanceof Error) {
        trackDataDogError("Activity submission.", { e });
        return handleError(e.message);
      }

      return navigate("/error");
    }
  };

  // eslint-disable-next-line consistent-return
  async function handleSubmit(
    values: RegistrationType,
    formProps: FormikHelpers<any>
  ) {
    if (submitButtonAction === SubmitAction.NEXT) {
      const updatedValues =
        eventCode && journeyHelper.isSportsFormWithEventCodeJourney(journey)
          ? {
              ...values,
              eventCode,
              eventName: eventRegistered?.title,
              eventStart: eventRegistered?.date_start,
              eventTypes: eventRegistered?.event_types,
            }
          : values;

      nextPage(updatedValues);
      formProps.resetForm({ values: updatedValues });
    }

    if (
      isSportsFormContactCentreJourney(journey) &&
      submitButtonAction ===
        SubmitAction.ACTIVITY_SUBMISSION_WITH_FUNDRAISING_PAGE &&
      values.hasOnlineFundraisingPage === "no"
    ) {
      setSubmitted(true);
      return activitySubmissionTask(values);
    }
    if (
      isSportsFormContactCentreJourney(journey) &&
      submitButtonAction ===
        SubmitAction.ACTIVITY_SUBMISSION_WITH_FUNDRAISING_PAGE &&
      values.hasOnlineFundraisingPage === "yes"
    ) {
      setSubmitted(true);
      return activitySubmissionWithFundraisingPageTask(values);
    }

    if (submitButtonAction === SubmitAction.ACTIVITY_SUBMISSION) {
      setSubmitted(true);
      if (journey === GIFTS_IN_WILLS_GUIDE) {
        trackEvent({ eventKey: Events.GIWG_SIGNUP_EVENT });
      }

      return activitySubmissionTask(values);
    }

    if (
      submitButtonAction ===
      SubmitAction.ACTIVITY_SUBMISSION_WITH_FUNDRAISING_PAGE
    ) {
      setSubmitted(true);
      return activitySubmissionWithFundraisingPageTask(values);
    }
  }

  const initialValues = { ...registration };

  // only for pages that have the activityDateTime field, if it does not have a
  // value yet set it's initialValue to the first option of the dropdown
  if (
    (fields.includes(Field.ACTIVITY_MONTH) &&
      !initialValues.activityDateTime) ||
    journey === DIY_COUCH_TO_5K_JOGGER
  ) {
    initialValues.activityDateTime = dateOptions[0].value;
  }

  // Default delivery method to email for journeys that have deliveryMethod
  if (
    !initialValues.deliveryMethod &&
    (fields.includes(Field.DELIVERY_METHOD) ||
      fields.includes(Field.DELIVERY_METHOD_2))
  ) {
    initialValues.deliveryMethod = "email";
  }

  function isRequired(field: string): boolean {
    return validationSchema.fields[field]?.tests?.some(
      // this is an internal Yup structure
      (test: Record<string, { name: string }>) =>
        test.OPTIONS.name === "required"
    );
  }

  // FORM FIELDS RENDERED
  return (
    <FormPageWrapper>
      <FormPageBannerText bannerText={bannerText} />
      <FormWrapper>
        {/* Optimizely development experiment demo */}
        {enabledExperimentHeaderControl && <p>Experiment control here</p>}
        {enabledExperimentHeadervariation && <p>Experiment variation here</p>}
        {/* Optimizely development experiment demo end */}
        <Heading h1>{heading}</Heading>
        {introText ? (
          <Box marginBottom="s" data-cy="intro-text-html">
            {renderCustomText(introText)}
          </Box>
        ) : null}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formProps: FormikProps<FormikValues>) => {
            const props: FormFieldProps = {
              ...formProps,
              activity,
              formJourneyPage,
              journey,
              journeyHelper,
              isRequired,
              optInMerchandise,
              setOptInMerchandise,
              navigate,
              eventRegistered,
              submitted,
              previousPage,
              setEvents,
              events,
              serverValidationError,
              setEventRegistered,
              getClickableLink,
              enabledExperimentGIWGVariation,
              trackEvent,
              giwgLookingForVariables: giwgLookingForVariables
                ? (giwgLookingForVariables.dropdown_value as GIWGLookingForVariables)
                : undefined,
              filterHandleChange: () =>
                filteredHandleChange(
                  formProps.handleChange,
                  formProps.values as RegistrationType,
                  formProps.setValues
                ),
            };
            return (
              <form onSubmit={formProps.handleSubmit} noValidate id="page-form">
                <ScrollToError />
                {fields &&
                  React.Children.toArray(
                    fields.map((field) => {
                      props.field = field;
                      switch (field) {
                        // PERSONAL INFO - why do we need this?

                        case Field.FIRST_NAME:
                          return <FirstName {...props} />;

                        case Field.LAST_NAME:
                          return <LastName {...props} />;

                        case Field.DATE_OF_BIRTH:
                          return <DateOfBirth {...props} />;

                        case Field.EMAIL_ADDRESS:
                          return <EmailAddress {...props} />;

                        case Field.PHONE_NUMBER:
                          return <PhoneNumber {...props} />;

                        case Field.GIFTS_IN_WILLS_GUIDE_IMAGE:
                          return <GiftsInWillsGuideImage />;

                        case Field.ADDRESS:
                          return <Address {...props} />;

                        // ACTIVITY AND FUNDRAISING INFO
                        case Field.ACTIVITY_TYPE:
                          return <ActivityType {...props} />;

                        case Field.ACTIVITY_DATE:
                          return <ActivityDate {...props} />;

                        case Field.ACTIVITY_MONTH:
                          return <ActivityMonth {...props} />;

                        case Field.MORE_INFO:
                          return <MoreInfo {...props} />;

                        case Field.FUNDRAISING_TARGET:
                          return <FundraisingTarget {...props} />;

                        case Field.RESTRICTION:
                          return <Restriction {...props} />;

                        case Field.ORGANISATION_NAME:
                          return <OrganisationName {...props} />;

                        // PACKS AND PRODUCTS
                        case Field.RENDER_IMAGE:
                          return (
                            <RenderImage
                              {...props}
                              renderImageSrc={renderFundraisingPackImage}
                            />
                          );

                        case Field.RENDER_GIGW_THANK_YOU_IMAGE:
                          return <RenderGIGWThankYouImage {...props} />;

                        case Field.T_SHIRT_SIZE:
                          return <TShirtSize {...props} />;

                        case Field.PRODUCT_QUANTITY:
                          return <ProductQuantity {...props} />;

                        case Field.OPT_IN:
                          return <OptIn {...props} />;

                        // CALL TO ACTION
                        // TODO: Configure CTA button label and links rationalise this section
                        case Field.LEGACIES_DIGITAL_GUIDE_CTA:
                          return <LegaciesDigitalGuideCTA {...props} />;

                        case Field.CALL_TO_ACTION:
                          return <CallToAction {...props} />;

                        case Field.SECOND_CALL_TO_ACTION:
                          return <SecondCallToAction {...props} />;

                        // FULFILMENT AND DELIVERY METHODS
                        case Field.DELIVERY_METHOD:
                          return <DeliveryMethod {...props} />;

                        case Field.DELIVERY_METHOD_2:
                          return <DeliveryMethod2 {...props} />;

                        // SPORTS JOURNEYS
                        // TODO: This should be in the run-60 data not in the component
                        case Field.FACEBOOK_FUNDRAISER_CTA:
                          return <FacebookFundraiserCTA {...props} />;

                        case Field.RUN_60_CTA:
                          return <Run60CTA {...props} />;

                        case Field.SU_2C_FB_CTA:
                          return <SU2C60CTA {...props} />;

                        // SPORTS FORM FIELDS
                        case Field.DYNAMIC_EVENT_NAME:
                          return <DynamicEventName {...props} />;

                        case Field.EVENT_LOOKUP:
                          return <EventLookup {...props} />;

                        case Field.HAVE_ENTERED_EVENT:
                          return <HaveEnteredEvent {...props} />;

                        case Field.HAS_ONLINE_FUNDRAISING_PAGE:
                          return <HasOnlineFundraisingPage {...props} />;

                        case Field.T_SHIRT_OR_VEST:
                          return <TShirtOrVest {...props} />;

                        case Field.MALE_FEMALE_STYLES:
                          return <MaleFemaleStyles {...props} />;

                        case Field.RADIO_SELECT_T_SHIRT_VEST_SIZE:
                          return <RadioSelectTShirtVestSize {...props} />;

                        case Field.SUPPORTER_NEED_PACK:
                          return <SupporterNeedPack {...props} />;

                        case Field.IRON_ON_TEXT:
                          return <IronOnText {...props} />;

                        case Field.CONTACT_CENTRE_SPORTS_PRODUCTS_LOOKUP:
                          return (
                            <ContactCentreSportsProductsLookup {...props} />
                          );

                        case Field.SPORTS_FORMS_CTA:
                          return <SportsFormsCTA {...props} />;

                        case Field.MERCHANDISE_TYPE:
                          return <MerchandiseType {...props} />;

                        case Field.RENDER_IMAGE_DIY_5K_JOGGER:
                          return (
                            <RenderImage
                              {...props}
                              renderImageSrc={renderDIY5kJoggerImage}
                            />
                          );

                        case Field.RENDER_IMAGE_PARK_RUNNER:
                          return (
                            <RenderImage
                              {...props}
                              renderImageSrc={renderDIYParkRunnerImage}
                            />
                          );

                        case Field.INFORMATION_LOOKING_FOR:
                          return <InformationLookingFor {...props} />;

                        default:
                          return null;
                      }
                    })
                  )}

                {(eventQuery?.isLoading || eventsQuery?.isLoading) && (
                  <Loader />
                )}
                {eventQuery?.error && (
                  <Text textColor="red">{eventQuery.error}</Text>
                )}
                {eventsQuery?.error && (
                  <Text textColor="red">{eventsQuery.error}</Text>
                )}

                <FormCollapseLabelWhyDoWeNeedThis
                  key={`why-do-we-need-this-${currentPage}`}
                  {...props}
                />

                {secondaryHeading && (
                  <Heading h2 marginTop="none">
                    {secondaryHeading}
                  </Heading>
                )}

                {secondaryFields &&
                  React.Children.toArray(
                    secondaryFields.map((field) => {
                      switch (field) {
                        case Field.ADDRESS:
                          return <Address {...props} />;
                        default:
                          return null;
                      }
                    })
                  )}

                <FormSubmitNextAndBackButton {...props} />

                {serverValidationError ? (
                  <Text textColor="red">{serverValidationError}</Text>
                ) : null}

                {fields.includes(Field.SUPPORT_CALL_BOX) ? (
                  <SupportCallBox />
                ) : null}
              </form>
            );
          }}
        </Formik>
      </FormWrapper>
    </FormPageWrapper>
  );
};

export default FormPage;
