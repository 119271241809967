import { Box, Select } from "@cruk/cruk-react-components";
import { ChangeEvent } from "react";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";
import StyledTextAreaField from "../../components/StyledTextAreaField";

const InformationLookingFor: React.FC<FormFieldProps> = ({
  values,
  giwgLookingForVariables,
  field,
  getFieldProps,
  setValues,
  handleChange,
  touched,
  errors,
  handleBlur,
  trackEvent,
  enabledExperimentGIWGVariation,
}: FormFieldProps) => {
  return enabledExperimentGIWGVariation ? (
    <>
      <Box marginBottom="s" key={field}>
        <Select
          label={giwgLookingForVariables ? giwgLookingForVariables.label : ""}
          {...getFieldProps("informationLookingFor")}
          required={true}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setValues({
              ...values,
            } as RegistrationType);
            handleChange(event);
            trackEvent({
              eventKey: "giwg_information_looking_for",
            });
          }}
          errorMessage={
            touched.informationLookingFor
              ? errors.informationLookingFor
              : undefined
          }
        >
          <option disabled value="">
            Select option
          </option>
          {giwgLookingForVariables &&
            giwgLookingForVariables.values.map(
              (opt: { name: string; value: string }) => (
                <option value={opt.value} key={opt.value}>
                  {opt.name}
                </option>
              )
            )}
        </Select>
      </Box>
      {values.informationLookingFor === "Other" && (
        <Box marginBottom="s" key="experiement">
          <StyledTextAreaField
            label={"Enter the information you are looking for"}
            aria-label="This is a text field to type information you're looking for"
            {...getFieldProps("informationLookingForFreeText")}
            onBlur={handleBlur}
            errorMessage={
              touched.informationLookingForFreeText
                ? errors.informationLookingForFreeText
                : undefined
            }
          />
        </Box>
      )}
    </>
  ) : null;
};

export default InformationLookingFor;
