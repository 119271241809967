import { Box, Button } from "@cruk/cruk-react-components";
import { FormFieldProps } from "../../types";

/**
 * Renders a secondary call-to-action button if the `secondCallToActionLabel` and `secondCallToActionLink`
 * properties are present in the activity object.
 *
 * @param {FormFieldProps} props - The properties passed to the component.
 * @param {Object} props.activity - The activity object containing the call-to-action details.
 * @param {Function} props.getClickableLink - A function to get the clickable link for the button.
 * @param {string} props.field - The field identifier.
 *
 * @returns {JSX.Element|null} A `Box` containing a `Button` if the call-to-action details are present, otherwise `null`.
 */
const SecondCallToAction = ({
  activity,
  getClickableLink,
  field,
}: FormFieldProps) =>
  activity.secondCallToActionLabel && activity.secondCallToActionLink ? (
    <Box marginBottom="s" key={field}>
      <Button
        appearance="secondary"
        type="button"
        size="m"
        href={getClickableLink(activity.secondCallToActionLink)}
      >
        {activity.secondCallToActionLabel}
      </Button>
    </Box>
  ) : null;

export default SecondCallToAction;
